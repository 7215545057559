<template>
  <div v-if="data.length" class="cooking_slider">
    <div class="cooking_slider__content container-padding">
      <h3>Кулинария</h3>
      <span class="cooking_slider__subtitle">
        Вкуснейшие кулинарные изделия собственного приготовления, которые обязательно удовлетворят запросы
        любого гурмана!
      </span>
      <SliderComponent
        :slider-options="cardsSliderOptions"
        :items="data"
        class="cooking_slider-items"
        v-slot="{ item }"
      >
        <CardComponent :data="item" />
      </SliderComponent>
      <!--      <a class="btn btn&#45;&#45;outline btn&#45;&#45;md" href="#">-->
      <!--        Посмотреть все-->
      <!--        <RemixIconComponent category="system" name="arrow-right-line" />-->
      <!--      </a>-->
    </div>
  </div>
</template>

<script>
import SliderComponent from "components/SliderComponent.vue";
import CardComponent from "components/CardComponent.vue";
import RemixIconComponent from "components/RemixIconComponent.vue";
export default {
  name: "CookingSliderComponent",
  components: { RemixIconComponent, CardComponent, SliderComponent },
  props: {
    data: {
      type: Array,
    },
  },
  data() {
    return {
      cardsSliderOptions: {
        slidesPerView: 1.5,
        spaceBetween: 24,
        pagination: false,
        clickable: false,
        preloadImages: true,
        breakpoints: {
          390: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          500: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          620: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        },
      },
    };
  },
};
</script>

<style lang="stylus">
.cooking_slider{
  display flex
  align-items: center;
  flex-direction column
  background-image url("/static/images/cooking_bg.svg")
  background-size cover
  justify-content center
  padding 131px 0
  background-color  #292929;

  +below(1100px) {
    padding: 40px 0
  }

  .swiper-slide{
    border-radius: 16px;
  }

  .swiper {
    border-radius: 16px;
  }

  &-items{
    margin-bottom 64px
    width 100%
    max-width: 1320px;

    +below(480px) {
      margin-bottom: 32px
    }
  }

  &__content {
    display flex
    align-items: center;
    flex-direction column
  }

  h3{
    font-weight: 500;
    font-size: 2rem;
    line-height: 38px;
    color: var(--white);

    +below(480px) {
      font-size: 1.375rem;
      line-height: 30px;
      margin-bottom: 16px
    }
  }

  &__subtitle{
    font-weight: 500;
    font-size: 1rem;
    line-height: 24px;
    text-align: center;
    margin-bottom 64px
    color: var(--white);
    max-width 655px

    +below(480px) {
      font-size: 0.875rem;
      line-height: 20px;
      margin-bottom: 32px
    }
  }
  .btn{
    padding: 12px 28px;
  }

}
</style>
